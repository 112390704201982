<template>
  <v-app>
    <div style="
      background: white;
      min-height: 100%
    ">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="card card-custom">
            <div class="card-body">
              <div class="row justify-content-start">
                <h4 class="text-left">Form Surat Domisili Usaha</h4>
                <div class="col-xl-12">
                  <b-form @submit.stop.prevent="formOnsubmit">
                    <b-form-group
                    id="input-group-id_card_number"
                    label="NIK:"
                    label-class="label-bold" label-for="input-id_card_number"
                  >
                    <b-form-input
                      id="input-id_card_number"
                      v-model="IdCardNumber"
                      placeholder="NIK"
                    ></b-form-input>
                    <small class="text-danger">{{
                      error.id_card_number
                    }}</small>
                  </b-form-group>

                  <b-form-group
                    id="input-group-family_number"
                    label="No. KK:"
                    label-class="label-bold" label-for="input-family_number"
                  >
                    <b-form-input
                      id="input-family_number"
                      v-model="FamilyNumber"
                      placeholder="No. KK"
                    ></b-form-input>
                    <small class="text-danger">{{ error.family_number }}</small>
                  </b-form-group>

                  <b-form-group id="input-group-phone">
                    <label for="input-phone" class="label-bold"
                      >No. WA
                      <em class="text-muted warning-message"
                        >(Pastikan nomor Whatsapp ini benar, karena akan
                        digunakan untuk mengirim surat ketika sudah jadi)</em
                      ></label
                    >
                    <b-form-input
                      id="input-phone"
                      v-model="form.phone"
                      placeholder="62851234......"
                    ></b-form-input>
                    <small class="text-danger">{{ error.phone }}</small>
                  </b-form-group>

                    <label for="" class="label-bold">Usaha Mulai Sejak:</label>
                    <div class="row mb-2">
                      <div class="col pt-3">
                        <v-dialog
                          ref="dialog"
                          v-model="modal2"
                          :return-value.sync="form.start_date"
                          persistent
                          width="350px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <b-input-group>
                              <b-input-group-prepend>
                                <button
                                  class="btn btn-secondary"
                                  type="button"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <i class="fas fa-calendar"></i>
                                </button>
                              </b-input-group-prepend>
                              <b-form-input
                                id="input-trigger-modal-patient"
                                v-model="form.start_date"
                                placeholder="Tanggal (YYYY-MM-DD)"
                                readonly
                              >
                              </b-form-input>
                            </b-input-group>
                          </template>

                          <v-date-picker
                            v-if="modal2"
                            v-model="form.start_date"
                            locale="id"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="modal2 = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialog.save(form.start_date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </div>
                    </div>

                    <b-form-group
                      id="input-group-name"
                      label="Nama Usaha:"
                      label-class="label-bold" label-for="input-name"
                    >
                      <b-form-input
                        id="input-name"
                        v-model="form.name"
                        placeholder="Nama Usaha"
                      ></b-form-input>
                      <small class="text-danger">{{ error.name }}</small>
                    </b-form-group>
                    <!-- <b-form-group
                      id="input-group-purpose"
                      label="Tujuan Pembuatan Surat:"
                      label-class="label-bold" label-for="input-purpose"
                    >
                      <b-form-input
                        id="input-purpose"
                        v-model="form.purpose"
                        placeholder="Tujuan Pembuatan Surat"
                      ></b-form-input>
                      <small class="text-danger">{{ error.purpose }}</small>
                    </b-form-group> -->
                    <b-form-group id="input-group-address">
                      <label for="input-address">Alamat Usaha: </label>
                      <b-form-textarea
                        id="input-address"
                        v-model="form.address"
                        placeholder="Alamat Usaha"
                        rows="4"
                        max-rows="8"
                      ></b-form-textarea>
                      <small class="text-danger">{{ error.address }}</small>
                    </b-form-group>

                    <!-- <b-form-group
                    id="input-group-hamlet_id"
                    label="No. WA Ketua RW:"
                    label-for="input-hamlet_id"
                    label-class="label-bold"
                  >
                    <treeselect
                      v-model="form.hamlet_id"
                      :multiple="false"
                      placeholder="Pilih No. WA Ketua RW"
                      :options="hamlets"
                    />
                    <small class="text-danger">{{ error.hamlet_id }}</small>
                  </b-form-group> -->

                  <b-form-group
                    id="input-group-purpose"
                    label="Keperluan:"
                    label-for="input-purpose"
                    label-class="label-bold"
                  >
                    <b-form-input
                      id="input-purpose"
                      v-model="form.purpose"
                      placeholder="Keperluan"
                    ></b-form-input>
                    <small class="text-danger">{{ error.purpose }}</small>
                  </b-form-group>

                    <!-- Submit & Cancel button -->
                    <b-button type="submit" variant="primary">Kirim</b-button>
                    <b-button
                      type="button"
                      class="ml-2"
                      variant="default"
                      @click="$router.push('/iframe/menu-surat-menyurat')"
                    >
                      Batal
                    </b-button>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import MainForm from "@/view/components/letters/Form.vue";
import { setCitizenMe, getCitizenMe } from "@/core/services/jwt.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    MainForm,
  },

  data() {
    return {
      form: {
        // id_card_number: getCitizenMe().id_card_number,
        // family_number: getCitizenMe().family_number,
        id_card_number: '',
        family_number: '',
        phone: "",
        title: "Surat Domisili Usaha",
        letter_type_id: 7,
        purpose_page: "website",
        resource: 3
      },
      timeout: null,
      timeout2: null,
      route: "bussiness-certificate-no-auth",
      // Error
      error: {
        citizen_id: "",
        hamlet_id: "",
        family_number: "",
        phone: "",
        name: "",
        address: "",
        purpose: "",
        id_card_number: "",
        purpose: ""
      },
      hamlets: [],
      config: {},
      modal2: false,
    };
  },

  methods: {
    async getHamletHeadOption() {
      let response = await module.setTreeSelectHamletHead("hamlets");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.hamlets = response.data;
        this.hamlets.unshift({
          label: "Pilih No. WA Ketua RW",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getMe() {
      let response = await module.get("get-citizen-by-id-card-number/654321");
      if (response != null) {
        setCitizenMe(JSON.stringify(response));
      }
    },
    reset() {
      for (const [key] of Object.entries(this.error)) {
        this.error[key] = "";
      }
      for (const [key] of Object.entries(this.form)) {
        console.log("ii", this.form[key]);
        console.log("key", [key]);
        if (
          key != "id_card_number" &&
          key != "letter_type_id" &&
          key != "title" &&
          key != "family_number" && key != "purpose_page" && key != "phone"
        ) {
          this.form[key] = "";
        }
      }
    },
    async formOnsubmit() {
      if (this.form.phone == "") {
        this.error.phone = "Kolom nomor wa tidak boleh kosong.";
        Swal.fire({
          title: "Gagal",
          text: "Harap periksa kembali form",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        throw new Error("validate error");
      } else {
        this.error.phone = "";
      }
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.reset();
      }
    },
  async checkIdCardNumber() {
      // Make Request
      let response = await module.checkIdCardNumber(
        this.form,
        "check-id-card-number"
      );
      // Check Response
      if (response.state == "error") {
        Swal.fire(
          "",
          "NIK anda belum terdaftar di sistem informasi desa. Silahkan datang ke kantor desa untuk mendaftarkan.",
          "error"
        );
        this.error.id_card_number =
          "NIK anda belum terdaftar di sistem informasi desa. Silahkan datang ke kantor desa untuk mendaftarkan.";
      } else {
        this.error.id_card_number = ""; this.form.phone = response.success.data.phone;
      }
    },
    async checkFamilyNumber() {
      // Make Request
      let response = await module.checkIdCardNumber(
        this.form,
        `check-compatibility/${this.form.id_card_number}/${this.form.family_number}`
      );
      console.log("resp", response);
      // Check Response
      if (response.success == "") {
        this.error.family_number =
          "NIK dan No. KK anda tidak sesuai. Silahkan input sesuai NIK dan No. KK anda.";
        Swal.fire(
          "",
          "NIK dan No. KK anda tidak sesuai. Silahkan input sesuai NIK dan No. KK anda.",
          "error"
        );
      } else {
        this.error.family_number = "";
      }
    },
  },
  computed: {
    IdCardNumber: {
      get() {
        this.error.id_card_number = "";
        return this.form.id_card_number;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.form.id_card_number = val;
          if (this.form.id_card_number.length >= 15) {
            this.checkIdCardNumber();
          }
        }, 500);
      },
    },
    FamilyNumber: {
      get() {
        this.error.family_number = "";
        return this.form.family_number;
      },
      set(val) {
        if (this.timeout2) clearTimeout(this.timeout2);
        this.timeout2 = setTimeout(() => {
          this.form.family_number = val;
          if (this.form.family_number.length >= 15) {
            this.checkFamilyNumber();
          }
        }, 500);
      },
    },
  },
  mounted() {
    this.getHamletHeadOption();
    console.log("citizenme", getCitizenMe());
  },
};
</script>

<style>
</style>